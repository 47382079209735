/**
* SEO component that queries for data with
*  Gatsby's useStaticQuery React hook
*
* See: https://www.gatsbyjs.org/docs/use-static-query/
*/

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
	description,
	lang,
	meta,
	image,
	postContent,
	title,
	url,
	year,
}) {
	const { site } = useStaticQuery(
		graphql`
		query {
			site {
				siteMetadata {
					description
					ogImage
					title
				}
			}
		}
		`
	)

	const metaDescription = description || site.siteMetadata.description
	const metaImage = image || site.siteMetadata.ogImage

	return (
		<Helmet
			htmlAttributes={ {
				lang,
			} }
			meta={ [
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: 'fb:app_id',
					content: '199820534480176',
				},
				{
					property: 'og:image:height',
					content: '1080',
				},
				{
					property: 'og:image:width',
					content: '1920',
				},
				{
					property: `og:title`,
					content: year
						? `${title} (${year}) → Best Horror Scenes → An ever growing collection featuring some of the best scenes in horror.`
						: `${title} → Best Horror Scenes → An ever growing collection featuring some of the best scenes in horror.`,
				},
				{
					property: 'og:url',
					content: url && !url.endsWith('/') ? `${url}/` : url,
				},
				{
					property: 'og:image',
					content: metaImage,
				},
				{
					property: `og:description`,
					content: postContent || metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:creator`,
					content: '@brandondurham',
				},
				{
					name: `twitter:site`,
					content: '@besthorrorscene',
				},
				{
					name: `twitter:title`,
					content: year
						? `${title} (${year}) → Best Horror Scenes → An ever growing collection featuring some of the best scenes in horror.`
						: `${title} → Best Horror Scenes → An ever growing collection featuring some of the best scenes in horror.`,
				},
				{
					name: 'twitter:url',
					content: url,
				},
				{
					name: 'twitter:image',
					content: metaImage,
				},
				{
					name: `twitter:description`,
					content: postContent || metaDescription,
				},
			].concat(meta) }
			title={ year ? `${title} (${year})` : title }
			titleTemplate={ `%s → ${site.siteMetadata.title}` }
		>
			<script crossOrigin="anonymous" SameSite="none Secure" src="//apis.google.com/js/platform.js"></script>
			<script crossOrigin="anonymous" SameSite="none Secure" src="//platform.twitter.com/widgets.js"></script>
		</Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	image: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
}

export default SEO
